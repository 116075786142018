// @ts-nocheck
import * as React from 'react';
import {version as uuidVersion} from 'uuid';
import {validate as uuidValidate} from 'uuid';
import {
    Box, Button,
    Container, Grid, Paper, Radio,
    Typography,
} from '@material-ui/core';

import {useParams} from 'react-router-dom';
import Divider from "@material-ui/core/Divider";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {
    DateInput, SelectInput, SimpleForm, Toolbar, NumberInput, TextInput
} from "react-admin";
import {GetOrganisation, OpenDays} from "../../../utilities/Common";
import {makeStyles} from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Link from "@material-ui/core/Link";
import {useEffect, useState} from "react";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import {useFormState} from "react-final-form";
import dateFormat from "dateformat";

const useStyles = makeStyles(theme => ({
    dateFields: {
        minWidth: '230px',
        margin: '10px'
    },
    dateFieldsHidden: {
        minWidth: '230px',
        margin: '10px',
        display: 'none'
    },
    textFields: {
        minWidth: '230px',
        margin: '10px',
    },
    optionTextFields: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px'
    },
    dowTextField: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '10px'
    },
    optionFirstHiddenTextFields: {
        minWidth: '220px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'none'
    },
    hiddenTextFields: {
        minWidth: '220px',
        margin: '10px',
        display: 'none'
    },
    selectFields: {
        minWidth: '220px',
        margin: '10px'
    },
    selectBooleanFields: {
        minWidth: '170px',
        margin: '10px'
    },

    LinkFields: {
        color: "#4183c4",
        padding: "20px"
    },
    hiddenRadioFields: {
        display: 'none',
        paddingRight: 0,
    },
    radioFields: {
        display: 'unset',
        paddingRight: 0,
        marginTop: '10px'
    }
}));


const RecurringCollection = props => {
    const {organisationUuid, recurringCollectionRequestId, mode} = useParams();
    let enableEditing = false;
    let modeDisplay = 'View';
    const classes = useStyles();
    let baseUrl = `${process.env.REACT_APP_KEGNOVA_API_URL}/recurring-collection/${recurringCollectionRequestId}`;
    const [recurringCollectionUrl, setRecurringCollectionUrl] = useState(baseUrl);
    let collectionUrl = `${process.env.REACT_APP_KEGNOVA_API_URL}/collections?originOrganisationUuid=${organisationUuid}`;
    const [allCollectionUrl, setAllCollectionUrl] = useState(collectionUrl)
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState(null);
    const [success, setSuccess] = useState(false);
    const [displayId, setDisplayId] = useState(null);
    const [isTypeWeek, setIsTypeWeek] = useState(true)
    const [collectionValueArray, setCollectionValueArray] = useState(null)
    const [organisation, setOrganisation] = useState(null);
    const [linkText, setLinkText] = useState("")
    const [link, setLink] = useState("")
    const [selectedValue, setSelectedValue] = useState(true);
    const [stateSeperationCount, setStateSeperationCount] = useState(1);
    const [isDateHidden, setIsDateHidden] = useState(true);


    useEffect(() => {
        setLoading(true);
        const now = new Date();

        (async () => {
            try {
                if (mode === 'add') {
                    setData({
                        "dayOfWeek": 1,
                        "type": 1,
                        "combineCollections": false,
                        "recurringCollectionStartAt": dateFormat(now, "isoDateTime"),
                        "separationCount": 1,
                        "dayOfMonth": 1,
                        "weekOfMonth": 1,
                        "createCollectionBefore":0,
                    })
                    return;
                }
                const result = await axios.get(recurringCollectionUrl, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Cache': 'no-cache',
                        'x-api-key': localStorage.getItem('apiKey')
                    }
                });
                let responseData = result.data;
                if (responseData.separationCount) {
                    setStateSeperationCount(responseData.separationCount)
                }

                if(responseData.recurringCollectionEndAt){
                    setIsDateHidden(false);
                }

                if (!responseData.dayOfMonth) {
                    responseData['dayOfMonth'] = 1
                }
                if (!responseData.weekOfMonth) {
                    responseData['weekOfMonth'] = 1
                }
                if (!responseData.dayOfWeek) {
                    responseData['dayOfWeek'] = 1
                }
                setDisplayId(result.data.displayId)
                if (result.data.type === 2) {
                    setIsTypeWeek(false)

                    if (result.data.dayOfMonth) {
                        //for dayOfMonth Selection
                        setSelectedValue(false)
                    }
                }
                setLinkText(result.data.templateCollectionRequest)
                setData(responseData);
                setLoading(false);
            } catch (error) {
                setErrorMessage('Retrieving Recurring Collections failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [recurringCollectionUrl]);


    useEffect(() => {
        (async () => {
            try {
                if (mode === 'delete') {
                    //then we don't need to fetch all the collections for this organisation
                    return;
                }
                const result = await axios.get(allCollectionUrl, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Cache': 'no-cache',
                        'x-api-key': localStorage.getItem('apiKey')
                    }
                });
                const localCollectionRequestArray = []
                result.data.forEach(element => {
                    const i = element.id;
                    var obj = {
                        'id': i,
                        'name': i
                    };
                    localCollectionRequestArray.push(obj);
                });
                setOrganisation(result.data[0].originOrganisationId)

                setCollectionValueArray(localCollectionRequestArray)

            } catch (error) {

                setErrorMessage('Retrieving Recurring Collections failed. ' + (error.response.length > 0) ? error.response.data.message : '');
                setLoading(false);
            }
        })();
    }, [allCollectionUrl]);


    useEffect(() => {
        setLink(`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/collections/${linkText}/details`)
    }, [linkText])

    useEffect(() => {
        if (!(!data && stateSeperationCount)) {
            setStateSeperationCount(1)
        }
    }, [isTypeWeek])


    const handleChange = (event) => {
        setSelectedValue(!selectedValue);
    };

    const successRedirect = () => {
        window.location.href = `/#/organisations/settings/${organisationUuid}`

    }

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/recurring-collection/${displayId}`;
            const options = {
                headers: {
                    'x-api-key': localStorage.getItem('apiKey'),
                }
            };

            await axios.delete(url, options);
            setSuccess(true);
            setErrorMessage(null);
            successRedirect()

        } catch (error) {
            if (error.response) {
                setErrorMessage('Error deleting package' + (error.response.length > 0) ? error.response.data.message : '');
            } else {
                setErrorMessage("Collection already deleted.")
            }
        }
    }

    const checkIsDateIso = (value) => {
        if (value) {
            if (value.length <= 10) {
                return value + 'T00:00:00+00:00'
            } else if (value.length > 10) {
                return value
            }
        } else {
            setErrorMessage("End date is not set")
        }

        return value
    }

    const handleSubmit = async (e, formState) => {
        e.preventDefault();
        let form = formState.values;

        let payload = {
            recurringCollectionStartAt: checkIsDateIso(form['recurringCollectionStartAt']),
            type: form['type'],
            separationCount: stateSeperationCount,
            collectionRequest: form['templateCollectionRequestId'],
            combineCollections: form['combineCollections'],
            createCollectionBefore: form['createCollectionBefore'],
            organisation: organisation,
        };

        if(form['recurringCollectionEndAt']){
            payload = {...payload, recurringCollectionEndAt: checkIsDateIso(form['recurringCollectionEndAt'])}
        }

        if (payload.type === 2) {
            if (selectedValue) {
                //true => day of week and week of month is asked
                payload = {
                    ...payload,
                    weekOfMonth: form['weekOfMonth'],
                    dayOfWeek: form['dayOfWeek'],
                    dayOfMonth: null
                }
            } else {
                //false => day of month is asked
                payload = {
                    ...payload,
                    dayOfMonth: form['dayOfMonth'],
                    weekOfMonth: null,
                    dayOfWeek: null
                }
            }

        } else {
            //weekly
            payload = {
                ...payload,
                dayOfWeek: form['dayOfWeek'],
                dayOfMonth: null,
                weekOfMonth: null
            }
        }

        try {
            let url = `${process.env.REACT_APP_TRACKS_URL}/api/recurring-collection`;
            const options = {
                headers: {
                    'Accept': 'application/json', 'Content-Type': 'application/json',
                    'x-api-key': localStorage.getItem('apiKey')
                }
            };

            if (mode === 'edit') {
                url = url + `/${displayId}`
                await axios.patch(url, payload, options);
                setData(null)
            } else if (mode === 'add') {
                await axios.post(url, payload, options);
                setData(null)
            }

            setSuccess(true);
            setErrorMessage(null);
        } catch (error) {
            setErrorMessage('Error updating package' + (error.response.length > 0) ? error.response.data.message : '');
        }

    }

    switch (mode) {
        case 'add':
            enableEditing = true;
            modeDisplay = 'Add a';
            break;
        case 'edit':
            enableEditing = true;
            modeDisplay = 'Edit';
            break;
        case 'delete':
            modeDisplay = 'Delete';
            enableEditing = true;
            break;
        default:
            modeDisplay = 'View';
            enableEditing = true;
            break;
    }

    if (!(uuidValidate(organisationUuid) && uuidVersion(organisationUuid) === 4)) {
        enableEditing = false;
    }

    if (!enableEditing) {
        return (
            <Container>
                <Box m={5}>
                    <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Error </AlertTitle>
                        Invalid page option. Please check the url/page options or go back to the previous page and try
                        again. If issue persist, contact Teach Team.
                    </Alert>
                </Box>
            </Container>
        )
    }

    const ActionToolbar = ({classes, ...props}) => {
        const formState = useFormState();
        return (
            <Toolbar {...props} >
                <Box>
                    <Button variant={'contained'} onClick={(e) => handleSubmit(e, formState)}
                            disabled={isSubmitting}><SaveIcon/>&nbsp; Save Package</Button>
                    <Link href={`/#/organisations/settings/${organisationUuid}`} underline='none' color={'inherit'}>
                        <Button variant={'contained'}> Cancel </Button>
                    </Link>
                </Box>
            </Toolbar>
        )
    };


    const changeFun = (value) => {
        const typeSelected = value.target.value;
        if (typeSelected === 1) {
            setIsTypeWeek(true)
            setSelectedValue(true)
        } else {
            setIsTypeWeek(false)
        }
    }


    const returnCollectionChoices = () => {
        return collectionValueArray
    }

    const handleCollectionChange = (e) => {
        setLinkText(e.target.value)
    }

    const updateIsDateHidden = () =>{
        setIsDateHidden(false);
        setData({...data, 'recurringCollectionEndAt' : dateFormat(new Date(), "isoDateTime")})
    };

    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    return (
        <Container>
            <Box m={2}>
                <Typography variant={'h4'}>Setup Recurring Collection </Typography>
            </Box>
            <Box m={1}>
                <Divider/>
            </Box>
            <Box m={2}>
                <Typography variant={'body1'} style={{fontWeight: 'bold'}}>{'Organisation'} <DoubleArrowIcon style={{
                    fontSize: '12px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    margin: 0
                }}/> <GetOrganisation uuid={organisationUuid}/>
                </Typography>
            </Box>
            <Box m={2}>
            </Box>
            <Box m={2}>
                <Typography variant={'h6'}>{modeDisplay} Recurring
                    Collection {recurringCollectionRequestId}</Typography>
            </Box>
            {success &&
                <Box m={2}>
                    <React.Fragment>
                        <Box m={1}></Box>
                        <Box display={'inline'}>
                            <Alert severity="success">
                                <AlertTitle variant={'h6'}> Success </AlertTitle>
                                {modeDisplay} Recurring Collection Request Successful!
                            </Alert>
                        </Box>
                    </React.Fragment>
                </Box>
            }
            {errorMessage &&
                <Box m={2}>
                    <React.Fragment>
                        <Box display={'inline'}>
                            <Alert severity="error">
                                <AlertTitle variant={'h6'}> Recurring Collections Error </AlertTitle>
                                {modeDisplay} Recurring Collection Request Failed!
                                <Box m={1}>
                                    {errorMessage}
                                </Box>
                            </Alert>
                        </Box>
                    </React.Fragment>
                </Box>
            }
            {mode === 'delete' ?
                <React.Fragment>
                    <Alert severity="info" style={{display: 'flex', width: '60%'}}>
                        <AlertTitle variant={'h6'}> Delete Recurring
                            Collection {recurringCollectionRequestId} </AlertTitle>
                        You're about to delete a Recurring Collection. Do you want to proceed?
                    </Alert>
                    <Box m={3}>
                        <Button variant={'contained'} onClick={(e) => handleDelete(e)}
                                style={{marginRight: '5px'}}>Yes</Button>
                        <Link href={`/#/organisations/settings/${organisationUuid}`} color={'inherit'}>
                            <Button variant={'contained'}> No </Button>
                        </Link>
                    </Box>
                </React.Fragment>
                :
                <Box m={2}>

                    {data &&
                        <SimpleForm toolbar={<ActionToolbar/>}>
                            <Grid container spacing={0} style={{width: '80%'}}>

                                <Grid item xs={8}>
                                    {isSafari ?
                                        <div><DateInput source="recurringCollectionStartAt" label={'Start Date'}
                                                        defaultValue={data.recurringCollectionStartAt}
                                                        className={classes.dateFields}/>
                                            <DateInput source="recurringCollectionEndAt" label={'End Date'}
                                                       defaultValue={data.recurringCollectionEndAt}
                                                       className={isDateHidden ? classes.dateFieldsHidden : classes.dateFields}/>
                                            <TextInput source="s" label={'End Date'}
                                                       defaultValue={"dd/mm/yyyy"}
                                                       onClick={updateIsDateHidden}
                                                       className={isDateHidden ? classes.dateFields : classes.dateFieldsHidden}/>
                                        </div>
                                        :
                                        <div><DateInput source="recurringCollectionStartAt" label={'Start Date'}
                                                        defaultValue={data.recurringCollectionStartAt}
                                                        className={classes.dateFields}/>
                                            <DateInput source="recurringCollectionEndAt" label={'End Date'}
                                                       defaultValue={data.recurringCollectionEndAt}
                                                       className={classes.dateFields}/>
                                        </div>
                                    }

                                </Grid>
                                <Grid container spacing={0} xs={12}>
                                    <Grid item>
                                        <NumberInput source="separationCount"
                                                     parse={(v) => {
                                                         setStateSeperationCount(v)
                                                         return v
                                                     }}
                                                     format={(v) => {
                                                         return stateSeperationCount
                                                     }}
                                                     label={'Repeat Interval'}
                                                     className={classes.textFields} defaultValue={data.separationCount}
                                                     min={1} max={isTypeWeek ? 4 : 12}/>
                                    </Grid>
                                    <Grid item>
                                        <SelectInput name="type" label="Recurring Type" choices={[
                                            {id: 1, name: 'Weekly'},
                                            {id: 2, name: 'Monthly'}
                                        ]} className={classes.selectFields} onChange={changeFun}
                                                     defaultValue={data.type}/>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row"
                                      justifyContent="flex-start"
                                      alignItems="flex-start">

                                    <Radio
                                        checked={selectedValue}
                                        onChange={handleChange}
                                        value="a"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'A'}}
                                        className={isTypeWeek ? classes.hiddenRadioFields : classes.radioFields}
                                    />

                                    <SelectInput name="dayOfWeek" label="Day of The Week" choices={OpenDays}
                                                 disabled={!selectedValue}
                                                 className={isTypeWeek ? classes.dowTextField : classes.optionTextFields}
                                                 defaultValue={data.dayOfWeek}
                                    />
                                    <NumberInput source="weekOfMonth" label={'Week of the month'}
                                                 disabled={!selectedValue}
                                                 className={isTypeWeek ? classes.hiddenTextFields : classes.optionTextFields}
                                                 defaultValue={data.weekOfMonth} max={4} min={1}/>
                                    {/*</Grid>*/}
                                    <Radio
                                        checked={!selectedValue}
                                        onChange={handleChange}
                                        value="b"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'B'}}
                                        className={isTypeWeek ? classes.hiddenRadioFields : classes.radioFields}
                                    />
                                    <NumberInput source="dayOfMonth" label={'Day of the month'} disabled={selectedValue}
                                                 className={isTypeWeek ? classes.hiddenTextFields : classes.optionTextFields}
                                                 defaultValue={data.dayOfMonth} max={31} min={1}/>


                                </Grid>
                                <Grid container direction="row"
                                      justifyContent="flex-start"
                                      alignItems="flex-start">
                                    <SelectInput source="createCollectionBefore" label={'Generate Collection Request'}
                                                 choices={[
                                                     {id: 0, name: 'Same day'},
                                                     {id: 1, name: '1 day before'},
                                                     {id: 2, name: '2 days before'},
                                                     {id: 3, name: '3 days before'},
                                                     {id: 4, name: '4 days before'},
                                                     {id: 5, name: '5 days before'},
                                                     {id: 6, name: '6 days before'},
                                                     {id: 7, name: '7 days before'},
                                                 ]}
                                                 className={classes.textFields}
                                                 defaultValue={data.createCollectionBefore ? data.createCollectionBefore : 0}/>
                                </Grid>

                                <Grid container direction="row"
                                      justifyContent="flex-start"
                                      alignItems="flex-start">
                                    {collectionValueArray &&
                                        <>
                                            <SelectInput name="templateCollectionRequestId"
                                                         label="Collection Request Template"
                                                         choices={returnCollectionChoices()}
                                                         className={classes.selectFields}
                                                         defaultValue={data.templateCollectionRequest}
                                                         optionValue="id"
                                                         onChange={handleCollectionChange}
                                            />
                                            <span style={{
                                                display: "inline-grid"
                                            }}>
                                                    {linkText &&
                                                        <Link href={link}
                                                              target="_blank"
                                                              variant="inherit" color="primary"
                                                              className={classes.LinkFields}>
                                                            View {linkText}
                                                        </Link>
                                                    }
                                                </span>

                                        </>
                                    }

                                    <SelectInput name="combineCollections" label="Combine Collection?" choices={[
                                        {id: true, name: 'Yes'},
                                        {id: false, name: 'No'}
                                    ]} className={classes.selectBooleanFields}
                                                 defaultValue={data.combineCollections}/>
                                </Grid>


                            </Grid>

                        </SimpleForm>
                    }
                    {success &&
                        <Box>
                            <Link href={`/#/organisations/settings/${organisationUuid}`} underline='none'
                                  color={'inherit'}>
                                <Button variant={'contained'}> Return to Home </Button>
                            </Link>
                        </Box>}

                </Box>

            }

        </Container>
    );
};

export default RecurringCollection;
