import React, {Fragment, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter, ReferenceInput, AutocompleteInput, SelectInput, useLoading, DateInput, TextInput,
    SelectArrayInput, NumberInput,
    useListContext, useList, useAuthenticated, useRefresh
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import EmptyRecordIcon from '@material-ui/icons/Inbox';

import {
    applyCurrentFilter,
    CustomPagination, DispatchStatuses,
    formatFeeDisplay, getMarketColorCode, LocationRoleTypes, OrderStatuses, OrderTypes,
    ProcessStatusDisplay, setCurrentFilters
} from "../../utilities/Common";

import {Container, LinearProgress, Box, CircularProgress, Button} from "@material-ui/core";
import DispatchesExport from "./DispatchesExport";
import DispatchesListActions from "./DispatchesListActions";
import BackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";
import {urlencoded} from "express";

export const styles = (theme => ({
    root: {},
    loading: {
        '& .MuiTable-root': {
            display: 'none'
        },
        '& .MuiTypography-body2': {
            display: 'none'
        },
        '& .MuiTablePagination-toolbar': {
            display: 'none'
        }

    },
    dispatchFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        }
    },
    organisationFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        width: '20em'
    },
    skuFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    },
    orderFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        maxWidth: '9em'
    },
    typeFilter: {
        minWidth: '8em'
    },
    locationRoleFilter: {
        minWidth: '13em'
    },
    statusFilter: {
        minWidth: '6em'
    },
    stateFilter: {
        '& .MuiFormHelperText-root.Mui-error': {
            display: 'none'
        },
        minWidth: '8em'
    }
}));

const useStyles = makeStyles(styles);

const getActionedAtDateFrom = () => {
    let days = 60;
    // @ts-ignore
    let result = new Date( new Date() - days * 24 * 60 * 60 * 1000);
    return result.toISOString().slice(0, 10);
}
const getActionedAtDateTo = () => {
    return new Date().toISOString().slice(0, 10);
}

const ListFilters = props => {
    const classes = useStyles();

    // setCurrentFilters(props,
    //     ['id', 'status[]', 'context', 'totalKegsFrom', 'totalKegsTo',
    //         'originOrganisationUuid', 'originLocationUuid', 'originCountryUuid', 'originLocationRole[]',
    //     'destinationOrganisationUuid', 'destinationLocationUuid', 'destinationCountryUuid', 'destinationLocationRole[]',
    //     'requestedAt_from_date', 'requestedAt_to_date', 'actionedAt_from_date', 'actionedAt_to_date',
    //     'orderId', 'kegSerialNumber', 'isStarlight', 'type', 'isPromoteRequired'],
    //     'dispatches');

    return (
        <Filter {...props}>
            <ReferenceInput source="id" reference="dispatches" label={'Dispatch No.'}
                            filter={{market: localStorage.getItem('mId')}}
                            className={classes.dispatchFilter}
                            alwaysOn resettable allowEmpty emptyValue={null}>
                <AutocompleteInput optionText="displayId" />
            </ReferenceInput>
            <SelectArrayInput source="status" label={'Status'} choices={DispatchStatuses} className={classes.statusFilter} resettable alwaysOn />
            <SelectInput label={'Context'} source="context" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Order' },
                { id: 2, name: 'Transfer' },
                { id: 3, name: 'Collection' },
                { id: 4, name: 'Stock Receipt' }
            ]} allowEmpty={false} alwaysOn />
            <NumberInput label={'No. of Kegs From'} source={'totalKegsFrom'} resettable />
            <NumberInput label={'No. of Kegs To'} source={'totalKegsTo'} resettable />
            <TextInput label={'Origin Organisation'} source={'originOrganisationName'} resettable={true} alwaysOn />
            <TextInput label={'Destination Organisation'} source={'destinationOrganisationName'} resettable={true} alwaysOn />
            <ReferenceInput source="originLocationUuid" reference="locations"
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Origin Location'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="originCountryUuid" reference="countries"
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Origin Country'} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source="originLocationRole" label={'Origin Location Role'}
                              choices={LocationRoleTypes} className={classes.locationRoleFilter} resettable />
            <ReferenceInput source="destinationLocationUuid" reference="locations"
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Destination Location'}
                            className={classes.organisationFilter} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="destinationCountryUuid" reference="countries"
                            sort={{ field: 'name', order: 'asc' }}
                            sortDefaultValues={{ field: 'name', order: 'asc' }} label={'Destination Country'} resettable allowEmpty emptyText={'All'}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source="destinationLocationRole" label={'Destination Location Role'}
                              choices={LocationRoleTypes} className={classes.locationRoleFilter} resettable />
            <DateInput label={'Created At From'} source={'createdAt_from_date'} alwaysOn resettable />
            <DateInput label={'Created At To'} source={'createdAt_to_date'} alwaysOn resettable />
            <DateInput label={'Due Date From'} source={'requestedAt_from_date'} alwaysOn resettable />
            <DateInput label={'Due Date To'} source={'requestedAt_to_date'} alwaysOn resettable />
            <DateInput label={'Actioned From'} source={'actionedAt_from_date'} alwaysOn resettable />
            <DateInput label={'Actioned To'} source={'actionedAt_to_date'} alwaysOn resettable />
            {/*<ReferenceInput source="orderId" reference="orders" label={'Order ID'}*/}
            {/*                // filter={{market: localStorage.getItem('mId')}}*/}
            {/*                // sort={{ field: 'orderId', order: 'desc' }}*/}
            {/*                // sortDefaultValues={{ orderId: 'desc'}}*/}
            {/*                alwaysOn resettable*/}
            {/*                className={classes.orderFilter} >*/}
            {/*    <AutocompleteInput optionValue={'displayId'} optionText="displayId" />*/}
            {/*</ReferenceInput>*/}
            <TextInput source="orderId" reference="orders" label={'Order ID'}
                       className={classes.orderFilter} alwaysOn resettable />
            <ReferenceInput source="kegSerialNumber" reference="kegs" label={'Keg Serial No.'}
                            filter={{market: localStorage.getItem('mId')}}
                            sort={{ field: 'id', order: 'desc' }}
                            sortDefaultValues={{ id: 'desc'}} alwaysOn resettable allowEmpty={false}>
                <AutocompleteInput optionText="serialNumber" />
            </ReferenceInput>
            <SelectInput label={'Starlight?'} source="isStarlight" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' },
            ]} allowEmpty={false} alwaysOn />
            <SelectInput label={'Type'} source="type" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Pooling' },
                { id: 2, name: 'Leasing' },
            ]} allowEmpty={false} alwaysOn />
            <SelectInput label={'Is Promoted?'} source="isPromoted" choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' },
            ]} allowEmpty={false} />
        </Filter>
    )
};

const ToOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.destinationOrganisationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.destinationOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.destinationOrganisation}</span>
                </Link>
                : null } <br/>
            { record.destinationLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.destinationOrganisationId}/locations/view/${record.destinationLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.destinationLocation}</span> <br/>
                    <span style={{color: "gray" }}>({record.destinationLocationRoleText})</span>
                </Link>
                : null }
        </React.Fragment>
    )
}
const FromOrganisation = ({record}) => {
    return(
        <React.Fragment>
            { record.originOrganisation ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.originOrganisationId}/overview`}>
                    <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.originOrganisation}</span>
                </Link>
                 : 'Not Allocated' } <br/>
            { record.originLocationId ?
                <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/organisations/${record.originOrganisationId}/locations/view/${record.originLocationId}`}>
                    <span style={{color: getMarketColorCode(localStorage.getItem('mId')) }}>{record.originLocation}</span> <br/>
                    <span style={{color: "gray" }}>({record.originLocationRoleText})</span>
                </Link>
                 : null }
        </React.Fragment>
    )
}

const DispatchesList = props => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const marketId = localStorage.getItem('mId');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const state = { setErrorMessage: setErrorMessage,
                         setSuccessMessage: setSuccessMessage }

    const defaultActionedAtDateFrom = getActionedAtDateFrom();
    const defaultActionedAtDateTo =  getActionedAtDateTo();
    let defaultFilterValues = { market: marketId, tz: Intl.DateTimeFormat().resolvedOptions().timeZone, isStarlight: 0, type: 1,
        actionedAt_from_date: defaultActionedAtDateFrom, actionedAt_to_date: defaultActionedAtDateTo};

    const refresh = useRefresh();
    const handleRefreshClick = () => {
        refresh();
    };

    // defaultFilterValues = applyCurrentFilter(defaultFilterValues,
    //     ['id', 'status[]', 'context', 'totalKegsFrom', 'totalKegsTo',
    //         'originOrganisationUuid', 'originLocationUuid', 'originCountryUuid', 'originLocationRole[]',
    //         'destinationOrganisationUuid', 'destinationLocationUuid', 'destinationCountryUuid', 'destinationLocationRole[]',
    //         'requestedAt_from_date', 'requestedAt_to_date', 'actionedAt_from_date', 'actionedAt_to_date',
    //         'orderId', 'kegSerialNumber', 'isStarlight', 'type', 'isPromoteRequired'],
    //     'dispatches');

    return (
        <Fragment>
            <Container maxWidth={false}>
                { loading && <LinearProgress /> }
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', padding:'5px', position: 'fixed',
                         bottom: '0', zIndex: 50, width: '90%'}}>
                    <Box p={1}><CircularProgress size={20} style={{color: '#fff'}} /> Please wait...</Box>
                </Box>
                }
                <List {...props}
                      // className={ loading ? classes.loading : classes.root }
                      // hasCreate={false}
                      actions={<DispatchesListActions state={state} refreshData={handleRefreshClick} />}
                      // filter={{ market: marketId, tz: localStorage.getItem('timezone') }}
                      filterDefaultValues={defaultFilterValues}
                      filters={ <ListFilters /> }
                      sort={{ field: 'id', order: 'desc' }}
                      perPage={10}
                      bulkActionButtons={false}
                      exporter={DispatchesExport}
                      pagination={<CustomPagination />}
                >
                    <Datagrid
                        // empty={<Box m={3} textAlign={'center'}> <EmptyRecordIcon /> </Box>}
                    >

                        <FunctionField source={'orderId'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/dispatches/${record.id}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.id}</span>
                        </Link> } label={'ID'} />

                        <FunctionField source={'context'} render={record => (record.contextText) } label={'Context'} />
                        <FunctionField source={'status'} render={record => (record.status === 0 ? 'New' : record.statusText) } label={'Status'} />
                        <NumberField source="totalKegs" label={'No. of Kegs'} />
                        {/*<NumberField source="badScans" label={'Bad Scans'} />*/}
                        <FunctionField source={'originOrganisation'} render={record => <FromOrganisation record={record} /> } label={'Origin'} />
                        <FunctionField source={'destinationOrganisation'} render={record => <ToOrganisation record={record} /> } label={'Destination'} />
                        <DateField source="createdAt" label={'Created At'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'} />
                        <DateField source="requestedAt" label={'Due Date'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'} />
                        <DateField source="actionedAt" label={'Actioned'} locales={(localStorage.getItem('mId') === '4') ? 'en-US' : 'en-GB'}
                          options={{ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }} />
                        <FunctionField source={'orderId'} render={record => <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/orders/${record.orderId}/details`}>
                            <span style={{fontWeight: 'bold', color: '#1887ad'}}>{record.orderId}</span>
                        </Link> } label={'Order'} />
                        <FunctionField source={'type'} render={record => (record.typeText) } label={'Type'} />
                        <FunctionField source={'isStarlight'} render={record => (record.isStarlightText) } label={'Starlight'} />
                        <FunctionField render={ record => (record.isPromoteRequired) ?
                            <Link href={`${process.env.REACT_APP_TRACKS_PORTAL_URL}/#/kegs/dispatches/${record.id}/promote`}
                                  style={{textDecoration: 'none'}}>
                                <Button variant={'contained'} size={'small'}>Promote</Button>
                            </Link> :
                            null
                        } label={''} sortable={false} />

                    </Datagrid>
                </List>
            </Container>
        </Fragment>
    );

}

export default DispatchesList;

