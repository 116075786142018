import React, {Fragment, useCallback, useState} from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    FunctionField, Filter,
    SelectArrayInput,
    NumberInput, TextInput, ReferenceInput, AutocompleteInput, useLoading, useNotify
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {ChargeFeeTypes, applyCurrentFilter, formatFeeDisplay, setCurrentFilters, CustomPagination} from "../../utilities/Common";
import {Container, LinearProgress, Button, Box, Divider, Typography, Paper, Drawer} from "@material-ui/core";
import ListActions from "../../components/list/ListActions";
import ChargesExport from "./ChargesExport";
import WaiveFeeIcon from "@material-ui/icons/MoneyOff";
import UnWaiveFeeIcon from "@material-ui/icons/AttachMoney";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ChargesListActions from "./ChargesListActions";
import TopToolbar from "../../components/layout/TopToolbar";
import CloseIcon from '@material-ui/icons/Close';

export const styles = (theme => ({
    countryFilter: {
        width: '10em'
    },
    organisationFilter: {
        width: '22em'
    },
}));

const useStyles = makeStyles(styles);

const ListFilters = ({classes, ...props}) => {
    setCurrentFilters(props,
        ['countryUuid', 'organisationUuid', 'kegSerialNumber', 'orderId']);

    return (
        <Filter {...props} style={{marginBottom:'60px'}}>
            <ReferenceInput source="countryUuid" reference="countries" label={'Country'}
                            filter={{marketId: localStorage.getItem('mId')}} sort={{id: 'asc'}} alwaysOn allowEmpty={false} resettable>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="organisationUuid" reference="organisations" sort={{name: 'asc'}}
                            filter={{market: localStorage.getItem('mId'), isProducer: 1, isActive: 1}}
                            resource="name" label={'Organisation'} className={classes.organisationFilter} alwaysOn allowEmpty={false} resettable>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput source={'kegSerialNumber'} label={'Keg Serial Number'} resettable />
            <NumberInput source={'orderId'} label={'Order Id'} resettable />
            <ReferenceInput source="skuUuid" reference="skus" label={'SKU'} resettable alwaysOn allowEmpty={false}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source="feeType" label={'Fee Description'}
                              choices={ChargeFeeTypes} className={classes.typeFilter} style={{ minWidth: '14em'}} resettable alwaysOn  />
        </Filter>
    )
};



const ListRowStyle = (record, index, defaultStyle = {}) => {
    if (record.status === 0)
        return {
            ...defaultStyle,
            backgroundColor: '#e7e6e7',
            opacity: 0.35
        };
};

const ChargeList = ({processData, ...props}) => {

    const processUuid = processData.childrenProcess.Charges.uuid;

    let defaultFilterValues = { processUuid: processUuid };
    defaultFilterValues = applyCurrentFilter(defaultFilterValues, ['countryUuid', 'organisationUuid', 'kegSerialNumber', 'orderId']);

    const loading = useLoading();
    const notify = useNotify();
    const classes = useStyles();
    const [loading2, setLoading2] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [waiveFeeSuccessful, setWaiveFeeSuccessful] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [hideBulk, setHideBulk] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);
    const [total, setTotal] = useState(0);
    const [filterValues, setFilterValues] = useState(null);
    const [currentSort, setCurrentSort] = useState(null);


    const updateFiltersAndTotal = useCallback((tot, fil, sor) => {
        setTotal(tot);
        setFilterValues(fil);
        setCurrentSort(sor);
    }, [refresh]);

    const handleClose = props => {
        setIsDrawerOpen(false);
        setHideBulk(true);
        setIsSelectAllSelected(false);
    };

    const openDrawer = useCallback(() =>{
        if(Object.keys(filterValues).length <= 1){
            let errMsg = `Please select atleast one filter`;
            notify(errMsg, 'warning');
        }else {
            setIsDrawerOpen(true);
            setHideBulk(false);
            setIsSelectAllSelected(true);
        }
    }, [refresh, filterValues]);


    let selectedUuids = [];

    const handleWaivedFee = async (payload, undoWaive = false) => {
        await saveWaivedFee(payload, undoWaive)
    };

    const waivedFeeAPI = async (payload, action, messageAction) => {
        try {
            let url = `${process.env.REACT_APP_KEGNOVA_API_URL}/charges/${action}`;
            const options = {
                headers: {
                    'Accept': 'application/json', 'Content-Type': 'application/json',
                    'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')
                }
            };
            const result = await axios.post(url, payload, options);
            // setIsUpdated(true);
            setLoading2(false);
            // notify('Waiving fee(s) is successful', 'info');
            setWaiveFeeSuccessful(`The selected charge(s) has been ${messageAction} successfully.`);
            setErrorMessage(false);
            setTimeout(function () {
                setWaiveFeeSuccessful(false);
            }, 20000);
            setRefresh(true);
            setTimeout(function () {
                setRefresh(false);
            }, 1000);
        } catch (error) {
            let errMsg = `Error in waiving of fees! ${error.response.data.message}`;
            notify(errMsg, 'warning');
            setLoading2(false);
            setErrorMessage(errMsg);
        }
    };

    const saveWaivedFee = async (payload, undoWaive) => {
        let action = 'void';
        let messageAction = 'waived';
        if(undoWaive) {
            action = 'undowaiveoff';
            messageAction = 'reinstated';
        }
        setLoading2(true);
        if(isSelectAllSelected){
            try {
                if(total > 5000){
                    throw new Error('Updating more than 5000 record at a time, is not allowed.');
                }
                if(total < 10){
                    throw new Error('Use checkbox to update less than 10 records');
                }
                let parentUrl = `${process.env.REACT_APP_KEGNOVA_API_URL}/charges?_end=${total < 10 ? 10 : total }&_sort=id&_start=0`;
                const options = {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
                        'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')},
                    params: {
                        ...filterValues,
                    }
                };
                const result = await axios.get(parentUrl, options);
                const uuidList = [];
                if(result.data){
                     result.data.forEach((item)=>{
                        uuidList.push({'uuid' : item.id})
                    })
                }

                await waivedFeeAPI(uuidList, action, messageAction)
            } catch (error) {
                let errMsg = error.message ? error.message : 'Error while fetching data, please try again';
                notify(errMsg, 'warning');
                setLoading2(false);
                setErrorMessage(errMsg);
            }
        }else {
            await waivedFeeAPI(payload, action, messageAction)
        }
    };

    const WaiveFeeBulkActionButtons = ({...props}) => {

        let selectedIds = props.selectedIds;
        selectedUuids = [];

        selectedIds.forEach((selectedId)=>{
            if(selectedId){
                selectedUuids.push({
                    uuid: selectedId
                })
            }
        });

        return (
            <Box m={1}>
                <Button variant={'contained'} onClick={()=> handleWaivedFee(selectedUuids, false) } style={{ position: 'absolute', marginLeft: '-1400px', width: '125px', marginTop: '-15px'}}><WaiveFeeIcon /> &nbsp; Waive Fee </Button>
                <Button variant={'contained'} onClick={()=> handleWaivedFee(selectedUuids, true) } style={{ position: 'absolute', marginLeft: '-1270px', width: '135px', marginTop: '-15px'}}><UnWaiveFeeIcon /> &nbsp; Enable Fee </Button>
            </Box>
        );
    };

    const canSelect = (record) => {

        if(record.status > 0) {
            return true
        }
        return false;
    }

    return (
        <Fragment>
            <Container style={{width: 'auto', overflowX: 'scroll', maxWidth: '100vw', padding: '8px'}}>
                { (loading || loading2) && <LinearProgress /> }

                    { waiveFeeSuccessful &&

                    <Alert severity="success" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Success </AlertTitle>
                        { waiveFeeSuccessful }
                    </Alert>

                    }
                    { errorMessage &&

                    <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                        <AlertTitle variant={'h6'}> Error </AlertTitle>
                        { errorMessage }
                    </Alert>

                    }
                { !refresh &&
                    <List {...props}
                        actions={<ChargesListActions updateFiltersAndTotal={updateFiltersAndTotal} openDrawer={openDrawer} />}
                        filter={{processUuid: processUuid}}
                        filterDefaultValues={defaultFilterValues}
                        filters={<ListFilters classes={classes} />} sort={{ field: 'id', order: 'DESC' }} perPage={10}
                        exporter={( processData && processData.processStatusText.toLowerCase().indexOf("completed") > -1 ) ? ChargesExport : false}
                        bulkActionButtons={ ((typeof processData.childrenProcess.DraftInvoice === "undefined") && hideBulk) ?  <WaiveFeeBulkActionButtons  /> :false}
                        pagination={<CustomPagination />}
                        >
                        <Datagrid rowStyle={ListRowStyle} >
                        <FunctionField render={record => (<span>{(record.status) ? 'Yes' : 'No'}</span>) } label={'Active?'} />
                        <TextField source="organisationName" label={'Organisation'} sortable={false}/>
                        <TextField source="cycleId" label={'Cycle Id'} sortable={false}/>
                        <TextField source="orderId" label={'Order Id'} sortable={false}/>
                        <TextField source="kegSerialNumber" label={'Keg Serial Number'} sortable={false}/>
                        <TextField source="skuName" label={'SKU'} sortable={false}/>
                        <TextField source="cycleStartDate" label={'Cycle Start Date'} sortable={false}/>
                        <TextField source="cycleEndDate" label={'Cycle End Date'} sortable={false}/>
                        <TextField source="endLocationName" label={'Cycle End Location'} sortable={false}/>
                        <TextField source="cycleEndRole" label={'Cycle End Location Role'} sortable={false}/>
                        <TextField source="cycleEndRegion" label={'Cycle End Location Region'} sortable={false}/>
                        <TextField source="chargeId" label={'Charge Id'} sortable={false}/>
                        <DateField source="applicableDate" label={'Applicable Date'} sortable={false}/>
                        <TextField source="description" label={'Fee Description'} sortable={false}/>
                        <NumberField source="quantity" label={'Quantity'} sortable={false}/>
                        <FunctionField render={record => ( formatFeeDisplay(record.unitAmount)) } label={'Unit Amount'} />
                        <FunctionField render={record => ( formatFeeDisplay(record.total)) } label={'Total'} />

                        </Datagrid>
                    </List>
                }

                <Drawer
                    variant="persistent"
                    open={isDrawerOpen}
                    anchor="right"
                    onClose={handleClose}
                >
                    <Paper square={true} >
                        <CloseIcon onClick={ handleClose } style={{float: 'right', padding: '10px'}}  />
                        <Box m={2}>
                            <Typography variant={'h5'} style={{minWidth: '290px'}}>Batch Update Charges</Typography>
                        </Box>
                        <Divider />
                        <Box m={2}>
                            <Alert severity="info">
                                {total} items selected
                            </Alert>
                        </Box>

                        <Box m={1} style={{display: 'flex',
                            marginTop: '30px',
                            marginBottom: '30px',
                            flexFlow: 'column',
                            alignItems: 'center'}}>
                            <Button variant={'contained'} onClick={()=> handleWaivedFee( [], false)}  style={{ width: '90%', marginBottom: '30px'}}><WaiveFeeIcon /> &nbsp; Waive Fee </Button>
                            <Button variant={'contained'} onClick={()=> handleWaivedFee( [], true)}  style={{ width: '90%'}}><UnWaiveFeeIcon /> &nbsp; Enable Fee </Button>
                        </Box>
                    </Paper>
                </Drawer>
            </Container>
        </Fragment>
    );

}

export default ChargeList;

